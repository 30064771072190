import React from 'react';
import styles from './HeaderStyle.module.css';

const Header = (props) => {

    return (
        <div className={styles.header} style={{ height: props.height }}>
            {
                props.background &&
                <img
                    className={styles.headerImg}
                    src={props.background}
                    alt="Alex petroserve"
                    style={props.noAnimation && { animation: "none" }}
                    title="Alex PetroServe"
                    loading='lazy'
                    width="100%"
                    height="100%"
                />
            }
            <div>
                {props.children}
            </div>
        </div>
    )
}

export default Header;