import { useState } from 'react';
import styles from './ProductImage.module.css';

const ProductImage = ({ images, title, width }) => {

    const [selected, setSelected] = useState(0);

    return (
        <div className={styles.productImage} style={{ width: width }}>
            <div className={styles.imagesList}>
                {
                    images.map(
                        (img, index) =>
                            <img
                                key={index}
                                className={`${styles.imageSample} + ${index === selected && `${styles.active}`}`}
                                src={img}
                                alt={title}
                                title={title}
                                loading='lazy'
                                width="55px"
                                height="55px"
                                onClick={() => setSelected(index)}

                            />)
                }
            </div>
            <div className={styles.imageContainer}>
                <img
                    className={styles.imagePrivew}
                    alt={title}
                    src={images[selected]}
                    title={title}
                    loading='lazy'
                    width="100%"
                    height="100%"
                />
            </div>
        </div>
    )
}

export default ProductImage;