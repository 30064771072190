import React from 'react';
import styles from './HeaderCard.module.css';
import { useTranslation } from 'react-i18next';

const HeaderCard = ({ title, body }) => {

    const { i18n } = useTranslation();

    return (
        <>
            <fieldset className={`${i18n.language === 'ar' && styles.ar} ${styles.title}`}>
                {title && <legend>{title}</legend>}
                <div className={styles.inner}>{body}</div>
            </fieldset>
        </>
    )
}

export default HeaderCard;