import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styles from './AboutUsStyle.module.css';
import { faNetworkWired, faHelmetSafety, faCompassDrafting, faWrench } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const AboutUs = () => {
    const { t } = useTranslation('aboutUs');
    return (
        <div className={styles.aboutUs}>
            <div className={styles.aboutUsDetails}>
                <span>{t('title')}</span>
                <h3><span>{t('Distinctive')}</span> {t('Approach')} <span>{t('Extraordinary')} </span>{t('Results')}</h3>
                <p>{t('description')}</p>
                <div>
                    <span>
                        <FontAwesomeIcon className={styles.aboutUsIcon} icon={faNetworkWired} /> {t('qualifiedWorkers')}
                    </span>
                    <span>
                        <FontAwesomeIcon className={styles.aboutUsIcon} icon={faHelmetSafety} /> {t('safty')}
                    </span>
                    <span>
                        <FontAwesomeIcon className={styles.aboutUsIcon} icon={faCompassDrafting} /> {t('bestMaterials')}
                    </span>
                    <span>
                        <FontAwesomeIcon className={styles.aboutUsIcon} icon={faWrench} /> {t('highQuality')}
                    </span>
                </div>
            </div>

            <div className={styles.pics}>
                <img src={process.env.PUBLIC_URL + '/Assets/Images/aboutUsImages/about-us.webp'} alt='skilld workers' title='skilld workers' loading='lazy' width="35%" height="210px" />
                <img src={process.env.PUBLIC_URL + '/Assets/Images/aboutUsImages/about-us1.webp'} alt='petroleum services' title='petroleum services' loading='lazy' width="35%" height="210px" />
                <img src={process.env.PUBLIC_URL + '/Assets/Images/aboutUsImages/about-us2.webp'} alt='marine services' title='marine services' loading='lazy' width="35%" height="210px" />
                <img src={process.env.PUBLIC_URL + '/Assets/Images/aboutUsImages/about-us3.webp'} alt='welding services' title='welding services' loading='lazy' width="35%" height="210px" />
            </div>
        </div>
    )
}

export default AboutUs;