import React from 'react';
import styles from './ContactUsBannerStyle.module.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import background from '../../assets/Images/backgroundImages/map.webp'

const ContactUsBanner = () => {
    const { t, i18n } = useTranslation('contactUsBanner');
    return (
        <div className={styles.contactUsBanner} style={{ backgroundImage: `url(${background})` }}>
            <div>
                <h2>{t('title')}</h2>
                <Link to='/contactUs' className={`${i18n.language === 'ar' && styles.ar} + ${styles.contactUsBtn}`}>
                    <span className={styles.circle} aria-hidden="true">
                        <span className={`${styles.icon} ${styles.lightArrow}`}></span>
                    </span>
                    <span className={styles.buttonText}>{t('contactUs')}</span>
                </Link>
            </div>
        </div>
    )
}

export default ContactUsBanner;