import React, { useEffect, useRef, useState } from 'react';

const Counter = ({ number, duration, symbole }) => {
    const [count, setCount] = useState("0");
    const ref = useRef(null);

    function useIsVisible(ref) {
        const [isIntersecting, setIntersecting] = useState(false);

        useEffect(() => {
            const observer = new IntersectionObserver(([entry]) =>
                setIntersecting(entry.isIntersecting)
            );

            observer.observe(ref.current);
            return () => {
                observer.disconnect();
            };
        }, [ref]);

        return isIntersecting;
    }

    const isVisible = useIsVisible(ref);

    useEffect(() => {
        let start = 0;
        const end = parseInt(number.substring(0, 3))
        if (start === end) return;

        let totalMilSecDur = parseInt(duration);
        let incrementTime = (totalMilSecDur / end) * 1000;

        let timer = setInterval(() => {
            if (isVisible) {
                start += 1;
                setCount(String(start) + number.substring(3))
                if (start === end) clearInterval(timer)
            }
        }, incrementTime);

    }, [number, duration, isVisible]);

    return (
        <span ref={ref}>
            {symbole} {count}
        </span>
    )
}

export default Counter;