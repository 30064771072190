import styles from './ContactUs.module.css';
import HeaderBackground from '../../../src/assets/Images/backgroundImages/contactUs.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import HeaderCard from '../../components/HeaderCard/HeaderCard';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header/Header';
import ContactUsForm from '../../components/ContactUsForm/ContactUsForm';
import Meta from '../../components/Meta/Meta';

const ContactUs = () => {
    const { t } = useTranslation(['contactUs', 'contact']);

    return (
        <>
            <Meta
                title="Contact us"
                description="Get in touch with our expert team"
                image="https://www.Alexpetroserve.com/Assets/Images/metaImages/APSlogo.webp"
                url="https://www.Alexpetroserve.com"
                type="website"
                ogdescription="contact Alex petroserve"
                canonical='contactUs'
            />

            <Header className={styles.contactHeader} noAnimation background={HeaderBackground} height="80vh">
                <HeaderCard body={t('headerCard')} />
            </Header>

            <div className={styles.contectUs}>

                <div className={styles.contactInfo}>
                    <div>
                        <FontAwesomeIcon className={styles.contactIcon} icon={faLocationDot} />
                        <div>
                            <h4>{t('address')}</h4>
                            <p>{t('street', { ns: "contact" })}, {t('city', { ns: "contact" })}</p>
                        </div>
                    </div>

                    <div className={styles.vertical}></div>

                    <div>
                        <FontAwesomeIcon className={styles.contactIcon} icon={faPhone} />
                        <div>
                            <h4>{t('phone')}</h4>
                            <p>{t('phone1', { ns: "contact" })}</p>
                        </div>
                    </div>

                    <div className={styles.vertical}></div>

                    <div>
                        <FontAwesomeIcon className={styles.contactIcon} icon={faEnvelope} />
                        <div>
                            <h4>{t('email')}</h4>
                            <p>{t('emailAddress', { ns: "contact" })}</p>
                        </div>
                    </div>
                </div>

                <div className={styles.contactForm}>
                    <ContactUsForm
                        formMessage={t('formMessage')}
                        form={t('form')}
                        emailPlaceholder={t('emailPlaceholder')}
                        phonePlaceholder={t('phonePlaceholder')}
                        messagePlaceholder={t('messagePlaceholder')}
                        submitPlaceholder={t('submitPlaceholder')}
                    />

                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13650.864152920625!2d29.953918!3d31.2008859!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14f5c5d48e8b0933%3A0x579730fc46b6166a!2s%D9%90Alex%20Petroserve%20petroleum%20services!5e0!3m2!1sen!2seg!4v1707791243503!5m2!1sen!2seg"
                        title='Address'
                        width="600"
                        height="450"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </>

    )
}

export default ContactUs;