import React, { useEffect } from 'react';
import './AnimationStyle.css';

const Animation = (props) => {

    const reveal = () => {

        var reveals = document.querySelectorAll(".reveal");

        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 150;

            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("active");
            } else {
                reveals[i].classList.remove("active");
            }
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", reveal);
    }, [])

    return (
        <div className='animation'>
            <div className={`container reveal ${props.fade ? props.fade : "fade-bottom"}`}>
                {props.children}
            </div>
        </div>
    )
}

export default Animation;