import { useTranslation } from 'react-i18next';
import styles from './ProjectDetails.module.css';
import { useParams } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Animation from '../../components/Animation/Animation';
import ImageGallery from '../../components/ImageGallery/ImageGallery';
import Meta from '../../components/Meta/Meta';

const ProjectDetails = () => {

    const { id } = useParams();
    const { t } = useTranslation('ourProjects');
    const projects = t('projects', { returnObjects: true });
    const project = projects.find(item => item.id === id);
    if (project?.testimonial) {
        var { testimonial, name, company, position } = project.testimonial;
    }

    return (
        <div>
            <Meta
                title={project.title}
                description={project.description}
                image="https://www.Alexpetroserve.com/Assets/Images/metaImages/APSlogo.webp"
                url="https://www.Alexpetroserve.com"
                type="product.item"
                ogdescription={project.description}
                canonical={`ourProjects/${id}`}
            />
            <Header background={project.projectBackgroundImage} noAnimation height="60vh" />
            <div>
                <div className={styles.projectInfo}>

                    <div className={styles.projectImageWrapper}>
                        <img src={project.imagePreview} alt={project.title} title={project.title} loading='lazy' width="100%" height="560px" />
                    </div>

                    <div className={styles.aboutProject}>
                        <h1 className={styles.projectTitle}>{project.title}</h1>
                        <p>{project.description}</p>
                    </div>
                </div>
            </div>

            <div className={styles.projectDescription}>
                <Animation fade="fade-right">
                    <div className={styles.projectHighlight}>
                        <div>
                            <h2>{t('projectOverview')}</h2>
                            <p>{project.overview}</p>
                        </div>
                        <img src={project.overviewImage} alt={project.title} title={project.title} loading='lazy' width="55%" height="300px" />
                    </div>
                </Animation>

                <Animation fade="fade-left">
                    <div className={styles.projectHighlight}>
                        <div>
                            <h2>{t('servicesProvided')}</h2>
                            <ul>
                                {project.servicesProvided.map((service, index) => (
                                    <li key={index}>{service}</li>
                                ))}
                            </ul>
                        </div>
                        <img src={project.servicesProvidedImage} alt={project.title} title={project.title} loading='lazy' width="55%" height="300px" />
                    </div>
                </Animation>

                <Animation fade="fade-right">
                    <div className={styles.projectHighlight}>
                        <div>
                            <h2>{t('projectHighlights')}</h2>
                            <ul>
                                {project.highlights.map((highlight, index) => (
                                    <li key={index}>{highlight}</li>
                                ))}
                            </ul>
                        </div>
                        <img src={project.highlightImage} alt={project.title} title={project.title} loading='lazy' width="55%" height="300px" />
                    </div>
                </Animation>

                <Animation fade="fade-left">
                    <div className={styles.projectHighlight}>
                        <div>
                            <h2>{t('resultsAndImpact')}</h2>
                            <p>{project.resultsAndImpact}</p>
                        </div>
                        <img src={project.impactImage} alt={project.title} title={project.title} loading='lazy' width="55%" height="300px" />
                    </div>
                </Animation>
            </div>

            {
                project.images && <ImageGallery data={project.images} />
            }

            {
                project.testimonial &&
                <div className={styles.testmonialContainer}>
                    <div className={styles.testimonial}>
                        <blockquote>
                            {testimonial}
                        </blockquote>
                        <div></div>
                        <p>
                            {name} &mdash; {position}, {company}
                        </p>
                    </div>
                </div>
            }
        </div>
    );
};

export default ProjectDetails;