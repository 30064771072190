import React from 'react'
import { Helmet } from 'react-helmet-async';

const Meta = ({ title, description, url, type, image, canonical, ogdescription }) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta property="og:url" content={url} />
            <meta property="og:type" content={type} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={ogdescription} />
            <meta property="og:image" content={image} />
            <link rel="canonical" href={`https://www.alexpetroserve.com/${canonical}`} />
        </Helmet>
    )
}

export default Meta;