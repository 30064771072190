import React from 'react';
import styles from './EquipmentCard.module.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const EquipmentCard = ({ src, name, description, path }) => {
    const { t } = useTranslation('essential')
    return (
        <div className={styles.equipmentCard}>
            <img
                className={styles.equipmentImage}
                src={src}
                alt={name}
            />
            <span className="arrow">{name}</span>
            <p>{description.length > 200 ? description.substring(0, 200) + "..." : description}</p>
            <Link to={path}>{t('explore')}</Link>
        </div>
    )
}

export default EquipmentCard;