import React from 'react';
import styles from './EquipmentBanner.module.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const EquipmentBanner = () => {

    const { t } = useTranslation(["jetPumps", "essential"]);

    const Cards = () => {
        return (
            <div className={styles.wrapper}>
                <div className={styles.card}></div>
            </div>
        )
    }

    return (
        <div className={styles.banner}>
            <div className={styles.equipmentDetails}>
                <h1>{t('title')}</h1>
                <p>{t('paragraph')}</p>
                <Link to="/jetpump.html" className={styles.bannerBtn} target='_blank'>{t('getMore', { ns: "essential" })}</Link>
            </div>

            <div className={styles.container}>
                <div className={styles.wrapper}>
                    <div className={styles.card}></div>
                </div>
                <Cards />
                <Cards />
                <Cards />
                <Cards />
                <Cards />
            </div>
        </div>
    )
}

export default EquipmentBanner;