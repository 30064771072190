import React from 'react';
import styles from './WhatWeOffer.module.css';
import { faRulerCombined, faLightbulb, faGears } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const WhatWeOffer = () => {
    const { t } = useTranslation('whatWeOffer');
    return (
        <div className={styles.whatWeOffer}>
            <h2>{t('title')}</h2>
            <hr />
            <div className={styles.offering}>
                <div>
                    <FontAwesomeIcon className={styles.icon} icon={faRulerCombined} />
                    <h3>{t('profesionalCrew')}</h3>
                    <p>{t('profesionalCrewDescription')}</p>
                </div>

                <div>
                    <FontAwesomeIcon className={styles.icon} icon={faLightbulb} />
                    <h3>{t('creativeSolutions')}</h3>
                    <p>{t('creativeSolutionsDescription')}</p>
                </div>

                <div>
                    <FontAwesomeIcon className={styles.icon} icon={faGears} />
                    <h3>{t('greatExperiance')}</h3>
                    <p>{t('greatExperianceDescription')}</p>
                </div>
            </div>
        </div>
    )
}

export default WhatWeOffer;