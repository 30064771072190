import { useTranslation } from 'react-i18next';
import styles from './Error.module.css';
import { Link } from 'react-router-dom';

const ErrorBoundary = () => {

    const { t } = useTranslation('notFound');
    return (
        <div className={styles.notFound}>
            <div>
                <h4>{t('notFound')}</h4>
                <p>{t('message')}</p>
                <Link to="/">Home</Link>
            </div>
        </div>
    )
}

export default ErrorBoundary;