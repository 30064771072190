import React, { useRef } from 'react';
import styles from './ContactUsForm.module.css';
import emailjs from '@emailjs/browser';

const ContactUsForm = ({ form, formMessage, emailPlaceholder, phonePlaceholder, messagePlaceholder, submitPlaceholder, submit }) => {

    const formRef = useRef();

    const handleSubmit = (e) => {
        e.preventDefault();
        emailjs.sendForm("service_cm0zyra", "template_ts3u4c9", formRef.current, "R0yh3W6rOXTeS3tm7")
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            })
        e.target.reset()
    }

    return (
        <div className={styles.contactForm} >
            <h2>{form}</h2>
            <p>{formMessage}</p>
            <form onSubmit={handleSubmit} ref={formRef}>
                <input name="user_email" type="email" placeholder={`${emailPlaceholder}*`} required />
                <input name="user_name" type="number" placeholder={`${phonePlaceholder}`} />
                <textarea name="message" placeholder={`${messagePlaceholder}`}></textarea>
                <input type="submit" value={`${submitPlaceholder}`} />
            </form>
        </div>

    )
}

export default ContactUsForm;