import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPaperPlane, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faYoutube, faWhatsapp, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import styles from './Footer.module.css';
import contact from '../../../assets/data/contactHref.json';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    let year = new Date().getFullYear();
    const { t } = useTranslation(['footer', 'contact']);

    return (
        <div className={styles.footer}>
            <div className={styles.footerContent}>

                <div className={styles.companyDetails}>
                    <h2>{t('companyName')}</h2>
                    <p>{t('description')}</p>
                </div>

                <div className={styles.officeDetails}>
                    <h2>{t('office')}</h2>
                    <div>
                        <span className={styles.address}>{t('headquarter')}</span>
                        <span>{t('address1')}</span>
                        <span>{t('city1')}</span>
                        <span className={styles.address}>{t('yard')}</span>
                        <span>{t('address2')}</span>
                        <span>{t('city2')}</span>
                        <span>{t('emailAddress', { ns: "contact" })}</span>
                        <span>{t('phone1', { ns: "contact" })}</span>
                    </div>
                </div>

                <div className={styles.services}>
                    <h2>{t('services')}</h2>
                    <div>
                        <Link className={styles.serviceLink} to={`/petroleumServices`}>{t('petroleum')}</Link>
                        <Link className={styles.serviceLink} to={`/marineServices`}>{t('marine')}</Link>
                        <Link className={styles.serviceLink} to={`/generalServices`}>{t('integrated')}</Link>
                        <Link className={styles.serviceLink} to={`/rentingServices`}>{t('renting')}</Link>
                    </div>
                </div>

                <div className={styles.newsletter}>
                    <h2>{t('newsLetter')}</h2>
                    <form
                        action="https://alexpetroserve.us12.list-manage.com/subscribe/post?u=a38c74bdd73255aef8852c8e9&amp;id=5bd0268dab&amp;f_id=0026b9e0f0"
                        method="post"
                        id="mc-embedded-subscribe-form"
                        name="mc-embedded-subscribe-form"
                        className="validate"
                        noValidate
                        target='_balnk'
                    >
                        <input
                            className={styles.emailField}
                            type='email'
                            name="EMAIL"
                            id="mce-EMAIL"
                            placeholder={t('emailPlaceholder')}
                        />
                        <div id="mce-responses" >
                            <div style={{ display: 'none' }} />
                            <div style={{ display: 'none' }} />
                        </div>
                        <button
                            type='submit'
                            name="subscribe"
                            className={styles.submitBtn}
                            title='submit'
                            id="mc-embedded-subscribe"
                        >
                            <FontAwesomeIcon icon={faPaperPlane} />
                        </button>
                    </form>

                    <div className={styles.socialLinks}>
                        <a
                            href={contact.facebookRehf}
                            title='APS Facebook page'
                            target='_blank'
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon className={`${styles.icon} ${styles.facebook}`} icon={faFacebook} size="lg" />
                        </a>
                        <a
                            href={contact.youtubeRehf}
                            title='APS youtube channle'
                            target='_blank'
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon className={`${styles.icon} ${styles.youtube}`} icon={faYoutube} size="lg" />
                        </a>
                        <a
                            href={contact.linkedinRehf}
                            title='APS Linkedin page'
                            target='_blank'
                            rel="noreferrer">
                            <FontAwesomeIcon className={`${styles.icon} ${styles.linkedin}`} icon={faLinkedin} size="lg" />
                        </a>
                        <a
                            href={contact.phoneNumberRehf}
                            title='Call us now' target='_blank'
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon className={`${styles.icon} ${styles.phone}`} icon={faPhone} size="lg" />
                        </a>
                        <a
                            href={contact.whatsappRehf}
                            title='Chat with us'
                            target='_blank'
                            rel="noreferrer">
                            <FontAwesomeIcon className={`${styles.icon} ${styles.whatsapp}`} icon={faWhatsapp} size="lg" />
                        </a>
                        <a
                            href={contact.emailRehf}
                            title='Email'
                            target='_blank'
                            rel="noreferrer">
                            <FontAwesomeIcon className={`${styles.icon} ${styles.email}`} icon={faEnvelope} size="lg" />
                        </a>
                    </div>
                </div>
            </div>
            <hr />
            <span className={styles.cr}>APS © {year} - All rights reserved</span>
        </div>
    )
}

export default Footer;