import styles from './generalServices.module.css';
import background from "../../../src/assets/Images/backgroundImages/generalServicesBackground.webp";
import { Link } from 'react-router-dom';
import HeaderCard from '../../components/HeaderCard/HeaderCard';
import Header from '../../components/Header/Header';
import ContactUsBanner from '../../components/ContactUsBanner/ContactUsBanner';
import { useTranslation } from 'react-i18next';
import Animation from '../../components/Animation/Animation';
import Meta from '../../components/Meta/Meta';

const GeneralServices = () => {

    const { t } = useTranslation(['ourServices', 'generalServices', 'essential']);
    const services = t('services', { ns: 'ourServices', returnObjects: true });
    const generalServices = services.filter(x => x.field.includes("generalServices"));
    const resources = t('resources', { ns: 'generalServices', returnObjects: true });

    return (
        <>
            <Meta
                title={t('title', { ns: 'generalServices' })}
                description={t('body', { ns: 'generalServices' })}
                image="https://www.Alexpetroserve.com/Assets/Images/metaImages/APSlogo.webp"
                url="https://www.Alexpetroserve.com"
                type="product.group"
                ogdescription={t('bodyHeader', { ns: 'generalServices' })}
                canonical='generalServices'
            />

            <Header noAnimation height="100vh" background={background}>
                <HeaderCard title={t('title', { ns: 'generalServices' })} body={t('body', { ns: 'generalServices' })} />
            </Header>

            <div className={styles.generalServicesHeader}>
                <div className={styles.generalServicesBody}>
                    <h2>{t('bodyHeader', { ns: 'generalServices' })}</h2>
                    <p>{t('bodyParagraph', { ns: 'generalServices' })}</p>
                </div>

                <div className={styles.relatedResources}>
                    <h3>{t('relatedResources', { ns: 'generalServices' })}</h3>
                    {
                        resources.map(r => (
                            <Link key={r.id} to={r.link}>{r.resorce}</Link>
                        ))
                    }
                </div>
            </div>

            <>
                {
                    generalServices.map(service => (
                        <div className={styles.generalServicesList} key={service.id} >
                            <div className={styles.generalService}>
                                <h2 className={`${styles.serviceTitle} ${styles.backgroundImage}`}>{service.service}</h2>
                                <p>{service.description}</p>
                                <Link to={`/generalServices/${service.id}`}>{t("getMore", { ns: 'essential' })}</Link>
                            </div>
                            <Animation>
                                <img
                                    className={styles.serviceImage}
                                    src={process.env.PUBLIC_URL + service.images[0]}
                                    alt={service.service}
                                    title={service.service}
                                    loading='lazy'
                                    width="100%"
                                    height="100%"
                                />
                            </Animation>
                        </div>
                    ))
                }
            </>
            <ContactUsBanner />
        </>
    )
}

export default GeneralServices;