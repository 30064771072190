import { useParams } from 'react-router-dom';
import styles from './EquipmentDetails.module.css';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header/Header';
import YoutubeLazyLoad from '../../components/YoutubeLazyLoad/YoutubeLazyLoad';
import ResponsiveImg from '../../components/ResponsiveImg/ResponsiveImg';
import RentingCards from '../../components/RentingCards/RentingCards';
import ProductImage from '../../components/ProductImage/ProductImage';
import Meta from '../../components/Meta/Meta';

const EquipmentDetails = () => {

    const { equipment, id } = useParams();
    const { t } = useTranslation(['rentingServices', 'equipmentTitles']);
    const equipmentData = t('equipment', { returnObjects: true });
    const product = equipmentData.find(p => p.id === id);
    let background = { backgroundImage: "url(/websiteImages/background-title.webp)", backgroundPosition: "unset", backgroundSize: "unset" };
    const suggestions = equipmentData.filter(p => p.category.replace(" ", "") === equipment && p.id !== id);

    return (
        <div>
            <Meta
                title={product.name}
                description={product.description}
                image={`https://www.Alexpetroserve.com` + product.bannerImage}
                url="https://www.Alexpetroserve.com"
                type="product.item"
                ogdescription={product.description}
                canonical={`rentingServices/${equipment}/${id}`}
            />

            <Header noAnimation height="20vh" />

            <div className={styles.productDetails}>
                <ProductImage images={product.images} title={product.name} />
                <div className={`${styles.productSection} arrow`}>
                    <h1>{product.name}</h1>
                    {
                        Object.entries(product.equipmentInfo).map(([key, value]) => (
                            <div key={key}>{key.replace(/([a-z])([A-Z])/g, '$1 $2')}: <span>{value}</span></div>
                        ))
                    }
                    {
                        product.brochure &&
                        <div><a href={product.brochure} target='_blank' rel="noreferrer">{t('getCatalog')}</a></div>
                    }
                </div>
            </div>

            <div className={styles.productBrife}>
                <div className="background-image" style={background}>
                    <h2>{product.subTitle}</h2>
                    <p>{product.description}</p>
                </div>
                <div className={styles.image}>
                    <img
                        src={product.bannerImage}
                        alt={product.name}
                        title={product.name}
                        loading='lazy'
                        width="100%"
                        height="100%"
                    />
                </div>
            </div>

            <div className={styles.productApplications}>
                <div className={styles.appImage}>
                    {
                        product.videoUrl ?
                            <YoutubeLazyLoad
                                videoUrl={product.videoUrl}
                                title={product.name}
                                caption={product.name}
                                thumbnailOverride={product.appImage}
                            />
                            :
                            <ResponsiveImg
                                className={styles.appImg}
                                src={product.appImage}
                                alt={product.name}
                            />
                    }
                </div>

                <div className={styles.applications}>
                    <h2 className="arrow">{t('applications', { ns: 'equipmentTitles' })}</h2>
                    <ul>
                        {product.applications.map((app, index) => (
                            <li key={index}>{app}</li>
                        ))}
                    </ul>
                </div>
            </div>

            <div className={`${styles.productBanner} background-image`} style={background}>
                <div className={styles.productFeatures}>
                    <h2>{t('features', { ns: 'equipmentTitles' })}</h2>
                    <ul>
                        {
                            product.features.map((feature, index) => (
                                <li key={index}>{feature}</li>
                            ))
                        }
                    </ul>
                </div>
            </div>

            <div className={styles.sugesstions}>
                {
                    suggestions.length > 0 &&
                    <RentingCards
                        data={suggestions}
                        title={equipment}
                    />
                }
            </div>
        </div>
    )
}

export default EquipmentDetails;