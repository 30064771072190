import styles from './Services.module.css';
import background1 from '../../assets/Images/backgroundImages/petroleumServicesBackground.webp';
import background2 from '../../assets/Images/backgroundImages/marineServicesBackground.webp';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Header from '../../components/Header/Header';
import HeaderCard from '../../components/HeaderCard/HeaderCard';
import Animation from '../../components/Animation/Animation';
import ContactUsBanner from '../../components/ContactUsBanner/ContactUsBanner';
import ServicesCard from '../../components/ServicesCard/ServicesCard';
import Meta from '../../components/Meta/Meta';

const Services = () => {

    const { services } = useParams();
    const { t } = useTranslation(['ourServices', 'servicesMetaData', 'essential']);
    const servicesData = t('services', { ns: "ourServices", returnObjects: true });
    const field = services;
    const service = servicesData.filter(x => x.field.includes(field));
    if (service.length === 0) {
        throw new Response("Not Found", { status: 404 });
    }

    return (
        <>
            <Meta
                title={t(`${field}`)}
                description={t(`${field}Body`, { ns: "servicesMetaData" })}
                image="https://www.Alexpetroserve.com/Assets/Images/metaImages/APSlogo.webp"
                url="https://www.Alexpetroserve.com"
                type="product.group"
                ogdescription={t(`${field}Body`, { ns: "servicesMetaData" })}
                canonical={`${field}`}
            />
            <Header noAnimation height="100vh" background={field === "petroleumServices" ? background1 : background2}>
                <HeaderCard title={t(`${field}`, { ns: "servicesMetaData" })} body={t(`${field}Body`, { ns: "servicesMetaData" })} />
            </Header>

            <div className={styles.petroServices}>
                <Animation>
                    <h1 className={`${styles.servicesHeading} arrow`}>
                        {t('message', { ns: "servicesMetaData" })} {t(`${field}`, { ns: "servicesMetaData" })}
                    </h1>
                </Animation>

                {
                    service.map(
                        (item) =>
                            <Animation key={item.id}>
                                <ServicesCard
                                    title={item.service}
                                    src={process.env.PUBLIC_URL + item.descriptionImage}
                                    body={item.description}
                                    path={`/${field}/${item.id}`}
                                />
                            </Animation>
                    )
                }
            </div>
            <ContactUsBanner />
        </>
    )
}

export default Services;