import styles from './equipment.module.css';
import background from '../../../src/assets/Images/backgroundImages/rentingServicesBackground.webp';
import { useParams } from 'react-router-dom';
import Header from '../../components/Header/Header';
import RentingCards from '../../components/RentingCards/RentingCards';
import { useTranslation } from 'react-i18next';
import Meta from '../../components/Meta/Meta';

const EquipmentCatagories = () => {

    const { equipment } = useParams();
    const { t } = useTranslation('rentingServices');
    const equipmentDetails = t('equipment', { returnObjects: true });
    const data = equipmentDetails.filter(x => x.category.toLowerCase().replace(" ", "") === equipment.replace('%20', '').replace(" ", ""));

    return (
        <div>
            <Meta
                title={equipment}
                description={equipment}
                url="https://www.Alexpetroserve.com"
                type="product.group"
                image="https://www.Alexpetroserve.com/Assets/Images/metaImages/APSlogo.webp"
                ogdescription="heavy equipment renting services"
                canonical={`${equipment}`}
            />
            <Header background={background} noAnimation height={"65vh"} />
            <div className={styles.equipmentCards}>
                <RentingCards data={data} title={data[0].category} />
            </div>
        </div>
    )
}

export default EquipmentCatagories;