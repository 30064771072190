import React, { useRef } from 'react';
import styles from './ResponsiveImg.module.css';

const ResponsiveImg = ({ containerHeight, alt, src, height }) => {

    let constrain = 20;
    let mouseOverContainer = useRef();
    let ex1Layer = useRef();

    function transforms(ix, iy, el) {
        const { height, width, x, y } = el.current.getBoundingClientRect();
        let calcX = (iy - y - (height / 2)) / constrain;
        let calcY = -(ix - x - (width / 2)) / constrain;
        let rotateX = calcX > 3 ? 3 : calcX < -3 ? -3 : calcX;
        let rotateY = calcY > 3 ? 3 : calcY < -3 ? -3 : calcY;
        return "perspective(300px) "
            + "rotateX(" + rotateX + "deg)"
            + "rotateY(" + rotateY + "deg)";
    };

    function transformElement(el, xyEl) {
        el.current.style.transform = transforms.apply(null, xyEl);
    }

    const handleRotate = (e) => {
        let xy = [e.clientX, e.clientY];
        let position = xy.concat([ex1Layer]);
        window.requestAnimationFrame(function () {
            transformElement(ex1Layer, position);
        });
    }

    const handleUnRotate = () => {
        ex1Layer.current.style.transform =
            "perspective(300px) "
            + "rotateX(" + 0 + "deg)"
            + "rotateY(" + 0 + "deg)";
    }

    return (
        <div
            onMouseMove={(e) => handleRotate(e)}
            onMouseOut={handleUnRotate}
            className={styles.imageContainer}
            ref={mouseOverContainer}
            style={{ height: containerHeight }}
        >
            <img
                alt={alt}
                title={alt}
                loading='lazy'
                width="100%"
                height="100%"
                ref={ex1Layer}
                src={src}
                className={styles.box}
                style={{ height: height }}
            />
        </div>
    )
}

export default ResponsiveImg;