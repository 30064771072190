import './RentingServicesStyle.css';
import background from '../../assets/Images/backgroundImages/rentingServicesBackground.webp';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header/Header';
import HeaderCard from '../../components/HeaderCard/HeaderCard';
import RentingCards from '../../components/RentingCards/RentingCards';
import ContactUsBanner from '../../components/ContactUsBanner/ContactUsBanner';
import Meta from '../../components/Meta/Meta';

const RentingServices = () => {

    const { t } = useTranslation('rentingServices');
    const equipment = t('equipment', { returnObjects: true });
    const equipmentGroupedByCatagories = equipment.reduce((group, equip) => {
        const { category } = equip;
        group[category] = group[category] ?? [];
        group[category].push(equip);
        return group;
    }, {});

    return (
        <>
            <Meta
                title={t('title')}
                description={t('body')}
                image="https://www.Alexpetroserve.com/Assets/Images/metaImages/APSlogo.webp"
                url="https://www.Alexpetroserve.com"
                type="product.group"
                ogdescription={t('body')}
                canonical='rentingServices'
            />

            <Header height="100vh" background={background} noAnimation>
                <HeaderCard title={t('header')} body={t('body')} />
            </Header>

            <div className="renting-services">
                <h1 className='services-heading arrow'>{t('title')}</h1>
                <p>{t('message')}</p>

                {
                    Object.entries(equipmentGroupedByCatagories).map(([key, value]) => (
                        <RentingCards
                            key={key}
                            data={value}
                            title={key.replace(/([a-z])([A-Z])/g, '$1 $2')}
                        />
                    ))
                }
            </div>

            <ContactUsBanner />
        </>
    )
}

export default RentingServices;