import { useEffect, useState } from 'react';
import styles from './NavbarStyle.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faEnvelope, faGlobe, faPhone, faXmark } from '@fortawesome/free-solid-svg-icons';
import data from '../../../assets/data/contactHref.json';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import navLogo from "../../../assets/Images/apsImages/NavLogo.webp";
import logo from "../../../assets/Images/apsImages/APSlogo.webp";

const Navbar = () => {
    const { t, i18n } = useTranslation('navbar');
    const [mobile, setMobile] = useState(false);
    const [color, setColor] = useState(false);

    const handleLanguage = () => {
        i18n.language === 'ar' ?
            i18n.changeLanguage('en') :
            i18n.changeLanguage('ar')
    }

    const mobileMode = (mode) => {
        setMobile(mode);
    }

    const changeColor = () => {
        if (window.scrollY >= 90) {
            setColor(true);
        } else {
            setColor(false);
        }
    }

    useEffect(() => {
        changeColor();
        window.addEventListener('scroll', changeColor);
    }, []);

    return (
        <nav className={styles.navbar}
            style={!color ? { backgroundColor: "transparent" } : { background: "#041A53" }}
        >

            <NavLink className={styles.logo} to="/">
                <img className={styles.logoImg} src={navLogo} alt={t('companyName')} width="70" height="70" loading='lazy' title={t('companyName')} />
                <span className={styles.comapnyTitle}>{t('companyName')}</span>
            </NavLink>

            <ul className={mobile ? `${styles.links} ${styles.active}` : styles.links}>

                <FontAwesomeIcon icon={faXmark}
                    className={`${styles.mobileNav} ${styles.close}`}
                    onClick={() => mobileMode(false)} />

                <img
                    className={`${styles.mobileNav} ${styles.logoImg}`}
                    src={logo}
                    alt={t('companyName')}
                    quality={100}
                    width={200}
                    height={120}
                    loading='lazy'
                />

                <li onClick={() => mobileMode(false)}>
                    <NavLink className={({ isActive }) => isActive ? `${styles.activeLink} ${styles.link}` : `${styles.link}`} to={`/`}>
                        {t('home')}
                    </NavLink>
                </li>

                <li onClick={() => mobileMode(false)}>
                    <NavLink className={({ isActive }) => isActive ? `${styles.activeLink} ${styles.link}` : `${styles.link}`} to={`/whoWeAre`}>
                        {t('whoWeAre')}
                    </NavLink>
                </li>

                <li className={styles.dropdown}>
                    <span className={styles.link}>
                        {t('ourServices')} &#x21b4;
                    </span>

                    <div
                        className={styles.dropdownContent}
                        style={
                            (!color && !mobile) ?
                                { backgroundColor: "#041a5369" }
                                :
                                { backgroundColor: "#041A53" }
                        }
                    >
                        <NavLink
                            className={({ isActive }) => isActive ? `${styles.activeLink} ${styles.link}` : `${styles.link}`}
                            to={`/petroleumServices`}
                            onClick={() => mobileMode(false)}
                        >
                            {t('petroleumServices')}
                        </NavLink>
                        <NavLink
                            className={({ isActive }) => isActive ? `${styles.activeLink} ${styles.link}` : `${styles.link}`}
                            to={`/marineServices`}
                            onClick={() => mobileMode(false)}
                        >
                            {t('marineServices')}
                        </NavLink>
                        <NavLink
                            className={({ isActive }) => isActive ? `${styles.activeLink} ${styles.link}` : `${styles.link}`}
                            to={`/generalServices`}
                            onClick={() => mobileMode(false)}
                        >
                            {t('generalServices')}
                        </NavLink>
                        <NavLink
                            className={({ isActive }) => isActive ? `${styles.activeLink} ${styles.link}` : `${styles.link}`}
                            to={`/rentingServices`}
                            onClick={() => mobileMode(false)}
                        >
                            {t('rentingServices')}
                        </NavLink>
                        <NavLink
                            className={({ isActive }) => isActive ? `${styles.activeLink} ${styles.link}` : `${styles.link}`}
                            to={`/jetpump.html`} target="_blank"
                            onClick={() => mobileMode(false)}
                        >
                            {t('waterJettingServices')}
                        </NavLink>
                    </div>
                </li>

                <li onClick={() => mobileMode(false)}>
                    <NavLink className={({ isActive }) => isActive ? `${styles.activeLink} ${styles.link}` : `${styles.link}`} to={`/ourProjects`}>
                        {t('ourProjects')}
                    </NavLink>
                </li>

                <li onClick={() => mobileMode(false)}>
                    <NavLink className={({ isActive }) => isActive ? `${styles.activeLink} ${styles.link}` : `${styles.link}`} to={`/contactUs`}>
                        {t('contactUs')}
                    </NavLink>
                </li>

                <li onClick={() => mobileMode(false)}> <a href={data.emailRehf} title="Mail Us" aria-label='mail us'><FontAwesomeIcon icon={faEnvelope} /></a></li>
                <li className={styles.languageChanger} onClick={() => { mobileMode(false); handleLanguage() }}><FontAwesomeIcon icon={faGlobe} /> {i18n.language.toUpperCase()}</li>
            </ul>

            <div className={styles.mobileNav}>
                <a href={data.phoneNumberRehf} aria-label="call us"><FontAwesomeIcon icon={faPhone} /></a>
                <li className={styles.languageChanger} onClick={() => { mobileMode(false); handleLanguage() }}><FontAwesomeIcon icon={faGlobe} /> {i18n.language.toUpperCase()}</li>
                <FontAwesomeIcon icon={faBars} className={styles.menuButton} onClick={() => mobileMode(true)} />
            </div>
        </nav >
    )
}

export default Navbar;