import React, { useState } from 'react';
import styles from './Services.module.css';
import YoutubeLazyLoad from '../YoutubeLazyLoad/YoutubeLazyLoad';

const Services = ({ services }) => {
    const [active, setActive] = useState(0);

    return (
        <div>
            <div className={styles.servicesNavigator}>
                {services.map(service => (
                    <div
                        onClick={() => setActive(service.id)}
                        className={active === service.id && `${styles.active}`}
                        key={service.id}
                    >
                        <img
                            src={process.env.PUBLIC_URL + service.icon}
                            alt={service.title}
                            title={service.title}
                            loading='lazy'
                            width="100%"
                            height="100%"
                        />
                        <h1>{service.title}</h1>
                    </div>
                ))}
            </div>

            <div className={styles.service}>
                <div>
                    {services[active].videoUrl ?
                        <YoutubeLazyLoad
                            caption={services[active].caption}
                            thumbnailOverride={process.env.PUBLIC_URL + services[active].src}
                            title={services[active].title}
                            videoUrl={services[active].videoUrl}
                        />
                        :
                        <img
                            className={styles.serviceImage}
                            src={process.env.PUBLIC_URL + services[active].src}
                            alt={services[active].title}
                            title={services[active].title}
                            width="100%"
                            height="100%"
                        />
                    }
                </div>

                <div>
                    <h2>{services[active].title}</h2>
                    <p className={styles.serviceBody}>{services[active].body}</p>
                </div>
            </div>
        </div>
    )
}

export default Services;