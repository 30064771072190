import React from 'react';
import styles from './ProjectCard.module.css';
import bannerBackground from '../../../src/assets/Images/backgroundImages/background-title.webp';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const ProjectCard = ({ project }) => {

    const { t } = useTranslation('essential');

    return (
        <div className={styles.projectCard} key={project.id}>
            <div className={`${styles.projectCardDetails} ${styles.backgroundImage}`} style={{ backgroundImage: `url(${bannerBackground})` }}>
                <h2>{project.title}</h2>
                <p>{project.description}</p>
                <Link to={`/ourProjects/${project.id}`}> {t('getMore')} </Link>
            </div>
            <div className={styles.image}>
                <img className={styles.projectImg} src={project.projectImage} alt={project.title} title={project.title} loading='lazy' width="100%" height="100%" />
            </div>
        </div>
    )
}

export default ProjectCard;