import { useTranslation } from 'react-i18next';
import styles from './Home.module.css';
import Header from '../../components/Header/Header';
import Brief from '../../components/Brief/Brief';
import MainServices from '../../components/MainServices/MainServices';
import AboutUs from '../../components/AboutUs/AboutUs';
import WhatWeOffer from '../../components/WhatWeOffer/WhatWeOffer';
import Partners from '../../components/Partners/Partners';
import Testimonials from '../../components/Testimonials/Testimonials';
import ContactUsBanner from '../../components/ContactUsBanner/ContactUsBanner';
import Meta from '../../components/Meta/Meta';
import EquipmentBanner from '../../components/EquipmentBanner/EquipmentBanner';

export default function Home() {
    const { t } = useTranslation('brief');

    return (
        <main className={styles.main}>
            <Meta
                title={t('title')}
                description={t('subTitle')}
                image="https://www.Alexpetroserve.com/Assets/Images/metaImages/APSlogo.webp"
                url="https://www.Alexpetroserve.com"
                type="website"
                ogdescription="Alex petroserve"
                canonical=''
            />
            <Header>
                <div className={styles.imageContainer}>
                </div>
                <div className={styles.headerTitle}>
                    <h1>{t('companyName')}</h1>
                    <h2>{t('subTitle')}</h2>
                </div>
            </Header>
            <Brief />
            <MainServices />
            <AboutUs />
            <EquipmentBanner />
            <WhatWeOffer />
            <Partners />
            <Testimonials />
            <ContactUsBanner />
        </main>
    )
}
