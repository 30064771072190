import './ImageGallery.css';
import { useEffect, useRef } from 'react';

const ImageGallery = ({ data }) => {

    let progress = 50;
    let startX = 0;
    let active = 0;
    let isDown = false;
    const speedWheel = 0.02;
    const speedDrag = -0.1;

    const getZindex = (array, index) =>
        array.map((_, i) => (index === i ? array.length : array.length - Math.abs(index - i)));

    const carouselRef = useRef(null);
    const cursorsRef = useRef([]);

    useEffect(() => {
        const items = carouselRef.current.querySelectorAll('.carousel-item');
        const cursors = cursorsRef.current;

        const displayItems = (item, index, active) => {
            const zIndex = getZindex([...items], active)[index];
            item.style.setProperty('--zIndex', zIndex);
            item.style.setProperty('--active', (index - active) / items.length);
        };

        const animate = () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            progress = Math.max(0, Math.min(progress, 100));
            // eslint-disable-next-line react-hooks/exhaustive-deps
            active = Math.floor((progress / 100) * (items.length - 1));

            items.forEach((item, index) => displayItems(item, index, active));
        };
        animate();

        const handleClick = (i) => {
            progress = (i / items.length) * 100 + 10;
            animate();
        };

        items.forEach((item, i) => {
            item.addEventListener('click', () => {
                handleClick(i);
            });
        });

        const handleWheel = (e) => {
            const wheelProgress = e.deltaY * speedWheel;
            progress += wheelProgress;
            animate();
        };

        const handleMouseMove = (e) => {
            if (e.type === 'mousemove') {
                cursors.forEach(($cursor) => {
                    $cursor.style.transform = `translate(${e.clientX}px, ${e.clientY}px)`;
                });
            }
            if (!isDown) return;
            const x = e.clientX || (e.touches && e.touches[0].clientX) || 0;
            const mouseProgress = (x - startX) * speedDrag;
            progress += mouseProgress;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            startX = x;
            animate();
        };

        const handleMouseDown = (e) => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            isDown = true;
            startX = e.clientX || (e.touches && e.touches[0].clientX) || 0;
        };

        const handleMouseUp = () => {
            isDown = false;
        };

        document.addEventListener('mousewheel', handleWheel);
        document.addEventListener('mousedown', handleMouseDown);
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('touchstart', handleMouseDown);
        document.addEventListener('touchmove', handleMouseMove);
        document.addEventListener('touchend', handleMouseUp);

        return () => {
            document.removeEventListener('mousewheel', handleWheel);
            document.removeEventListener('mousedown', handleMouseDown);
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('touchstart', handleMouseDown);
            document.removeEventListener('touchmove', handleMouseMove);
            document.removeEventListener('touchend', handleMouseUp);
        };
    }, []);

    return (
        <div className="carousel" ref={carouselRef}>
            {
                data.map(d => (
                    <div className="carousel-item" key={d.id}>
                        <div className="carousel-box">
                            <div className="num">{d.num}</div>
                            <img src={d.src} alt={d.title} title={d.title} width="100%" height="100%" loading='lazy' />
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default ImageGallery