import React from 'react';
import styles from './BriefStyle.module.css';
import { useTranslation } from 'react-i18next';
import logo from "../../assets/Images/apsImages/APSlogo.webp";

const Brief = () => {
    const { t } = useTranslation('brief');
    return (
        <div className={styles.brief}>

            <div className={styles.briefLogo}>
                <img
                    src={logo}
                    alt="Alex PetroServe"
                    title="Alex PetroServe"
                    loading='lazy'
                    width="100%"
                    height="100%"
                />
            </div>

            <div className={styles.briefBanner}>
                <h1>
                    {t('companyName')}
                </h1>
                <p>
                    {t('tagLine')}
                </p>
            </div>

            <hr />
        </div>
    )
}

export default Brief;