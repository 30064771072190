import { useTranslation } from 'react-i18next';
import Services from '../Services/Services';
import styles from './MainServices.module.css';

const MainServices = () => {

    const { t } = useTranslation('mainServices');
    var services = t('services', { returnObjects: true });

    return (
        <div className={styles.mainServices}>
            <h1>{t('title')}</h1>
            <hr />
            <Services services={services} />
        </div>
    )
}

export default MainServices;