import background from '../../../src/assets/Images/backgroundImages/whoWeAre.webp';
import styles from './WhoWeAre.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/Header/Header';
import HeaderCard from '../../components/HeaderCard/HeaderCard';
import YoutubeLazyLoad from '../../components/YoutubeLazyLoad/YoutubeLazyLoad';
import ResponsiveImg from '../../components/ResponsiveImg/ResponsiveImg';
import ContactUsBanner from '../../components/ContactUsBanner/ContactUsBanner';
import { useTranslation } from 'react-i18next';
import titleBackground from '../../assets/Images/backgroundImages/background-title.webp';
import Meta from '../../components/Meta/Meta';

const WhoWeAre = () => {

    const { t, i18n } = useTranslation(['whoWeAre', 'essential']);
    var values = t('values', { returnObjects: true })
    let backgroundImage = { backgroundImage: `url(${titleBackground})` };

    return (
        <div className={styles.whoWeAre}>
            <Meta
                title={t('title')}
                description={t('headerCard')}
                image="https://www.Alexpetroserve.com/Assets/Images/metaImages/APSlogo.webp"
                url="https://www.Alexpetroserve.com"
                type="article"
                ogdescription={t('headerCard')}
                canonical='whoWeAre'
            />
            <Header noAnimation background={background}>
                <HeaderCard title={t('title')} body={t('tagLine')} />
            </Header>

            <div className={styles.aboutCompany}>
                <div>
                    <span>{t('welcome')}</span>
                    <h1>{t('companyName')}</h1>
                    <hr />
                    <p>{t('aboutUs')}</p>
                </div>
                <div>
                    <p>{t('whoWeAre')}</p>
                </div>
            </div>

            <div className={styles.whoWeAreGrid}>

                <div className={`${styles.gridBox} ${styles.statistics} ${styles.backgroundImage}`} style={backgroundImage}>
                    <span>250+</span>
                    <p>{t('projects')}</p>
                </div>

                <div className={`${styles.gridBox} ${styles.statistics} ${styles.backgroundImage}`} style={backgroundImage}>
                    <span>150+</span>
                    <p>{t('employers')}</p>
                </div>

                <div className={`${styles.gridBox} ${styles.statistics} ${styles.backgroundImage}`} style={backgroundImage}>
                    <span>100+</span>
                    <p>{t('clients')}</p>
                </div>

                <div className={`${styles.gridBox} ${styles.value} ${styles.backgroundImage}`} style={backgroundImage}>
                    <p>{t('alexpetroserveParagraph')}</p>
                    <a className={styles.btn} href="/alexpetroserve.pdf" rel="noreferrer" alt="Alex Petroserve company profile" target='_blank'>{t('companyProfile', { ns: 'essential' })} <FontAwesomeIcon icon={faFileArrowDown} /></a>
                </div>

                <div className={`${styles.gridBox} ${styles.cultureImage}`}>
                    <YoutubeLazyLoad
                        caption="Alex petroserve"
                        thumbnailOverride={"/Assets/Images/whoWeAre/alexpetroserve.webp"}
                        title={"culture"}
                        videoUrl={"https://www.youtube.com/embed/Fowz8D9kq1w?&autoplay=1"}
                    />
                </div>

                <div className={`${styles.gridBox} ${styles.companyValues} ${styles.backgroundImage}`} style={backgroundImage}>
                    <h2 className='arrow'>{t('valuesTitle')}</h2>
                    <p>{t('valuesParagraph')}</p>
                </div>

                {
                    values.map((v) => (
                        <div key={v.id} className={`${styles.gridBox} ${styles.value} ${styles.backgroundImage}`} style={backgroundImage}>
                            <span>{v.value}</span>
                            <p>{v.description}</p>
                        </div>
                    ))
                }

                <div className={`${styles.gridBox} ${styles.image} ${styles.backgroundImage}`} style={backgroundImage}>
                    <ResponsiveImg
                        title="culture"
                        src="Assets/Images/whoWeAre/whoweare-1.webp"
                        alt="culture"
                        height="70vh"
                    />
                </div>

                <div className={`${styles.gridBox} ${styles.culture}`}>
                    <h2 className='arrow'>{t('culture')}</h2>
                </div>

                <div className={`${styles.gridBox} ${styles.m1} ${styles.backgroundImage}`} style={backgroundImage}>
                    <ResponsiveImg
                        title="culture"
                        alt="culture"
                        src="Assets/Images/whoWeAre/whoweare-2.webp"
                        height="410px"
                    />
                </div>

                <div className={`${i18n.language === 'ar' && styles.ar} ${styles.gridBox} ${styles.m2} ${styles.companyMassage}  ${styles.backgroundImage}`} style={backgroundImage}>
                    <p>{t('message')}</p>
                    <span>{t('ceo')}</span>
                </div>
            </div>
            <ContactUsBanner />
        </div>
    )
}

export default WhoWeAre;