import React from 'react';
import Footer from './Footer/Footer';
import Navbar from './NavBar/Navbar';
import ScrollButton from '../ScrollButton/ScrollButton';
import ScrollToTop from '../ScrollToTop/ScrollToTop';

const Layout = ({ children }) => {
    return (
        <>
            <Navbar />
            <ScrollToTop>
                {children}
                <ScrollButton />
            </ScrollToTop>
            <Footer />
        </>
    )
}

export default Layout;