import styles from './ServicesCard.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const ServicesCard = ({ title, src, path, body }) => {

    const { t, i18n } = useTranslation(['essential']);

    return (
        <div className={styles.serviceBox}>
            <img
                alt={title}
                src={src}
                title={title}
                loading='lazy'
                width="40%"
                height="310px"
            />
            <Link to={path} className={styles.serviceCard}>
                <div>
                    <h2>{title}</h2>
                    <p>{body}</p>
                    <span className={styles.exploreButton}>

                        {t('explore')}
                        {i18n.language === 'ar' && <FontAwesomeIcon icon={faAngleLeft} />}
                        {i18n.language === 'en' && <FontAwesomeIcon icon={faAngleRight} />}

                    </span>
                </div>
            </Link>
        </div>
    )
}

export default ServicesCard;