import styles from './TestimonialsCard.module.css';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const TestimonialsCard = ({ data }) => {

    const { i18n } = useTranslation();
    const [current, setCurrent] = useState(0);
    const handleNextTestimonial = () => {
        current >= data.length - 1 ? setCurrent(0) : setCurrent(current + 1);
    }

    const handlePreviousTestimonial = () => {
        current === 0 ? setCurrent(data.length - 1) : setCurrent(current - 1);
    }

    return (
        <div className={styles.testimonialsCard}>
            <div className={`${i18n.language === 'ar' && styles.ar} + ${styles.navigators}`}>
                <FontAwesomeIcon className={styles.navIcon} icon={faChevronLeft} onClick={handlePreviousTestimonial} />
                <FontAwesomeIcon className={styles.navIcon} icon={faChevronRight} onClick={handleNextTestimonial} />
            </div>

            <div className={styles.testimonialsSection}>
                <div className={styles.testimonials}>
                    <p>{data[current]?.testimonial}</p>
                    <div className={styles.client}>
                        <img
                            alt={data[current]?.name}
                            src={process.env.PUBLIC_URL + data[current]?.image}
                            title={data[current]?.name}
                            loading='lazy'
                            width="70px"
                            height="70px"
                        />
                        <p>{data[current]?.name} <span>, {data[current]?.postion}</span></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TestimonialsCard;
