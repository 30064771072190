import { useState } from 'react';
import './FlodCardStyle.css';

const FlodCard = ({ data }) => {

    const CardInfo = props => {
        const [isActive, setActive] = useState(props.id === 0 ? true : false);
        const handleClick = () => {
            setActive(!isActive)
        }

        return (
            <div className="card-info-wrapper">
                <div className='card-info' onClick={handleClick}>
                    <h3>{props.title}</h3>
                    <button>
                        <svg className={isActive ? 'active' : ''} viewBox="0 0 320 512" width="100" title="angle-down">
                            <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" />
                        </svg>
                    </button>
                </div>
                <div className={isActive ? 'info active' : 'info'}>{props.body}</div>
            </div>
        )
    }

    return (
        <div className='container'>
            {data.map(item => <CardInfo key={item.id} id={item.id} title={item.title} body={item.body} />)}
        </div>
    )
}

export default FlodCard;