import React, { useState } from 'react';
import styles from './Youtube.module.css';

const YoutubeLazyLoad = ({ videoUrl, title, caption, thumbnailOverride }) => {
    const [showVideo, setShowVideo] = useState(false);

    return (
        <div className={styles.youtube}>
            {showVideo ? (
                <iframe
                    width={560}
                    height={315}
                    src={videoUrl}
                    frameBorder={0}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title={title}
                    className={styles.youtubeFrame}
                />
            ) : (
                <div
                    type="button"
                    onClick={() => setShowVideo(true)}
                    className={styles.playButton}
                    aria-label={`Play video ${title}`}
                >
                    <img
                        src={thumbnailOverride}
                        alt={title}
                        className={styles.placeholderImage}
                        title={title}
                        loading='lazy'
                        width="100%"
                        height="100%"
                    />
                    <div className={styles.playIcon}>
                        <svg
                            v="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            enableBackground="new 0 0 104 104"
                            viewBox="0 0 104 104"
                        >
                            <path
                                fill="none"
                                stroke="#FFFFFF"
                                strokeWidth="4"
                                strokeMiterlimit="10"
                                d="M26,35h52L52,81L26,35z"
                            />
                            <circle
                                className={styles.videoPlayCircle}
                                fill="none"
                                stroke="#DA3D25"
                                strokeWidth="5"
                                strokeMiterlimit="10"
                                cx="52"
                                cy="52"
                                r="50"
                            />
                        </svg>
                        <span className={styles.videoPlayOutline}></span>
                    </div>
                </div>
            )}
            {caption && !showVideo && <p className={styles.caption}>{caption}</p>}
        </div>
    )
}

export default YoutubeLazyLoad;