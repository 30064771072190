import styles from './PartnersStyle.module.css';
import { useTranslation } from 'react-i18next';

const Partners = () => {
  const { t, i18n } = useTranslation('partners');
  const partners = t('partners', { returnObjects: true });
  const Logos = () => {
    return (
      <>
        {
          partners.map((partner) =>
            <img
              src={process.env.PUBLIC_URL + partner.img}
              alt={partner.name}
              title={partner.name}
              key={partner.id}
              loading='lazy'
              width="100%"
              height="100%"
            />)
        }
      </>
    )
  }

  return (
    <div className={styles.partners}>
      <h2>{t('title')}</h2>
      <div className={styles.logos}>
        <div className={`${i18n.language === "ar" ? styles.logosSlideAr : styles.logosSlide}`}>
          <Logos />
        </div>
        <div className={`${i18n.language === "ar" ? styles.logosSlideAr : styles.logosSlide}`}>
          <Logos />
        </div>
      </div>
      <p>{t('description')}</p>
    </div>
  )
}

export default Partners;