import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons';
import './ScrollButtonStyle.css';

const ScrollButton = () => {

    const [visible, setVisible] = useState(false)

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        const toggleVisible = () => {
            const scrolled = document.documentElement.scrollTop;
            if (scrolled > 300) {
                setVisible(true)
            }
            else if (scrolled <= 300) {
                setVisible(false)
            }
        };
        window.addEventListener('scroll', toggleVisible);
    }, [])


    return (
        <button aria-label="scroll-up" className='scroll-button'>
            <FontAwesomeIcon icon={faArrowAltCircleUp} onClick={scrollToTop}
                style={{ display: visible ? 'inline' : 'none' }} />
        </button>
    );
}

export default ScrollButton;
