import styles from './ourProjects.module.css';
import background from '../../assets/Images/backgroundImages/ourProjects.webp';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header/Header';
import HeaderCard from '../../components/HeaderCard/HeaderCard';
import ProjectCard from '../../components/ProjectCard/ProjectCard';
import Meta from '../../components/Meta/Meta';
// import Testimonials from '../../components/Testimonials/Testimonials';

const OurProjects = () => {

    const { t } = useTranslation('ourProjects');
    const projects = t('projects', { returnObjects: true });

    return (
        <div className={styles.ourProjects}>
            <Meta
                title={t('header')}
                description={t('headerCard')}
                image="https://www.Alexpetroserve.com/Assets/Images/metaImages/APSlogo.webp"
                url="https://www.Alexpetroserve.com"
                type="website"
                ogdescription="Alex petroserve's projects"
                canonical='ourProjects'
            />
            <Header background={background} noAnimation>
                <HeaderCard title={t('header')} body={t('body')} />
            </Header>

            <div className={styles.ourProjectsHeader}>
                <h1>{t('ourProjectsHeader')}</h1>
                <span>{t('ourProjectsParagraph')}</span>
            </div>

            {projects.map(project => (<ProjectCard key={project.id} project={project} />))}
            {/* <Testimonials/> */}
        </div>
    )
}

export default OurProjects;