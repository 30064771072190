import './App.css';
import { Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from './components/Layout/Layout';
import Home from './pages/Home/Home';
import ContactUs from './pages/ContactUs/ContactUs';
import WhoWeAre from './pages/WhoWeAre/WhoWeAre';
import GeneralServices from './pages/GeneralServices/GeneralServices';
import ServicesDetails from './pages/ServicesDetails/ServicesDetails';
import OurProjects from './pages/OurProjects/OurProjects';
import ProjectDetails from './pages/ProjectsDetails/ProjectsDetails';
import Services from './pages/Services/Services';
import RentingServices from './pages/RentingServices/RentingServices';
import EquipmentCatagories from './pages/Equipment/Equipment';
import EquipmentDetails from './pages/EquipmentDetails/EquipmentDetails';
import ErrorBoundary from './pages/Error/Error';
import { useTranslation } from 'react-i18next';
import Loading from './components/Loading/Loading';

function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout><Home /></Layout>,
      errorElement: <ErrorBoundary />
    },
    {
      path: "contactUs",
      element: <Layout><ContactUs /></Layout>,
      errorElement: <ErrorBoundary />
    },
    {
      path: "whoWeAre",
      element: <Layout><WhoWeAre /></Layout>,
      errorElement: <ErrorBoundary />
    },
    {
      path: ":services",
      element: <Layout><Services /></Layout>,
      errorElement: <ErrorBoundary />
    },
    {
      path: "generalServices",
      element: <Layout><GeneralServices /></Layout>,
      errorElement: <ErrorBoundary />
    },
    {
      path: "rentingServices",
      element: <Layout><RentingServices /></Layout>,
      errorElement: <ErrorBoundary />
    },
    {
      path: "rentingServices/:equipment",
      element: <Layout><EquipmentCatagories /></Layout>,
      errorElement: <ErrorBoundary />
    },
    {
      path: "rentingServices/:equipment/:id",
      element: <Layout><EquipmentDetails /></Layout>,
      errorElement: <ErrorBoundary />
    },
    {
      path: ":services/:id",
      element: <Layout><ServicesDetails /></Layout>,
      errorElement: <ErrorBoundary />
    },
    {
      path: "ourProjects",
      element: <Layout><OurProjects /></Layout>,
      errorElement: <ErrorBoundary />
    },
    {
      path: "ourProjects/:id",
      element: <Layout><ProjectDetails /></Layout>,
      errorElement: <ErrorBoundary />
    },
    {
      path: "/error",
      element: <ErrorBoundary />,
    }
  ]);

  return (
    <RouterProvider router={router} />
  );
}

export default function WrappedApp() {
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();
  return (
    <Suspense fallback={<Loading />}>
      <App />
    </Suspense>
  )
}