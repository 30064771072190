import styles from './TestimonialsStyle.module.css';
import Counter from '../Counter/Counter';
import TestimonialsCard from '../TestimonialsCard/TestimonialsCard';
import { useTranslation } from 'react-i18next';
import background from '../../assets/Images/backgroundImages/testimonialsBackground.webp';

const Testimonials = () => {

    const { t } = useTranslation('testimonials');
    var clients = t('clients', { returnObjects: true });

    return (
        <div className={styles.clientsTestimonials} style={{ backgroundImage: `url(${background})` }}>

            <h2>{t('title')}</h2>

            <div className={styles.testimonials}>
                <TestimonialsCard data={clients} />

                <div className={styles.statics}>
                    <div>
                        <Counter number={t('efficiencyRate')} duration={2} symbole="%" />
                        <span>{t('efficiency')}</span>
                    </div>

                    <div>
                        <Counter number={t('satisfactionRate')} duration={2} symbole="%" />
                        <span>{t('satisfaction')}</span>
                    </div>

                    <div>
                        <Counter number={t('projectsNum')} duration={3} symbole="+" />
                        <span>{t('projects')}</span>
                    </div>

                    <div>
                        <Counter number={t('completionRate')} duration={3} symbole="%" />
                        <span>{t('completion')}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Testimonials;