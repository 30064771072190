import React from 'react';
import styles from './Loading.module.css';
import gearImg from '../../assets/Images/apsImages/gear.webp';
import logoImg from '../../assets/Images/apsImages/NavLogo.webp';

const Loading = () => {
    return (
        <div className={styles.loading}>
            <img className={styles.gear} src={gearImg} alt='Alex petroserve' title='Alex petroserve' width="100%" height="100%" loading='lazy' />
            <img className={styles.logo} src={logoImg} alt='Alex petroserve' title='Alex petroserve' width="100%" height="100%" loading='lazy' />
        </div>
    )
}

export default Loading