import styles from './ServicesDetails.module.css';
import background1 from '../../../src/assets/Images/backgroundImages/pertroleumServiceDetails.webp';
import background2 from '../../../src/assets/Images/backgroundImages/marineServiceDetails.webp';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header/Header';
import HeaderCard from '../../components/HeaderCard/HeaderCard';
import YoutubeLazyLoad from '../../components/YoutubeLazyLoad/YoutubeLazyLoad';
import Animation from '../../components/Animation/Animation';
import FlodCard from '../../components/FlodCard/FlodCard';
import ProjectCard from '../../components/ProjectCard/ProjectCard';
import { Link, useParams } from 'react-router-dom';
import ServicesCard from '../../components/ServicesCard/ServicesCard';
import Meta from '../../components/Meta/Meta';

const ServicesDetails = () => {

    const { services, id } = useParams();
    const field = services;
    const { t } = useTranslation(['ourServices', 'ourProjects', 'essential', 'servicesMetaData']);
    const servicesData = t('services', { ns: "ourServices", returnObjects: true });
    const ourProjects = t('projects', { ns: "ourProjects", returnObjects: true });
    const service = servicesData.find(x => x.id === id);
    const suggestionService = servicesData.filter(x => x.id !== id && x.field === field).slice(0, 3);
    const projects = ourProjects.filter(x => x.field === field || x.title.includes(service.service));

    return (
        <div className={styles.serviceDetails}>
            <Meta
                title={service.service}
                description={t(`${field}Body`, { ns: "servicesMetaData" })}
                image="https://www.Alexpetroserve.com/Assets/Images/metaImages/APSlogo.webp"
                url="https://www.Alexpetroserve.com"
                type="product.item"
                ogdescription={t(`${field}Body`, { ns: "servicesMetaData" })}
                canonical={`${field}`}
            />

            <Header
                background={field === "petroleumServices" ? background1 : background2}
                noAnimation
            >
                <HeaderCard title={t(`${field}`, { ns: "servicesMetaData" })} body={t(`${field}Body`, { ns: "servicesMetaData" })} />
            </Header>

            <div className={styles.serviceInfo}>
                <div className={styles.infoMedia}>
                    {
                        service.videoUrl ?
                            <YoutubeLazyLoad
                                videoUrl={service.videoUrl}
                                title={service.service}
                                thumbnailOverride={service.descriptionImage}
                            />
                            :
                            <img className={styles.serviceMainImage} src={service.descriptionImage} alt={service.service} title={service.service} loading='lazy' width="100%" height="500px" />

                    }
                </div>
                <div className={styles.aboutService}>
                    <h1>{service.service}</h1>
                    <p>{service.description}</p>
                    <FlodCard data={service.serviceInfo} />
                </div>
            </div>

            <div className={styles.serviceImages}>
                {
                    service.images.map((img, index) => (
                        <Animation key={index}>
                            <img src={img} alt={service.service} title={service.service} loading='lazy' width="250px" height="200px" />
                        </Animation>
                    ))
                }
            </div>

            <h3 className={styles.featuresTitle}>{t('serviceFeatures', { ns: "essential" })}</h3>
            <div className={styles.serviceFeatures}>
                {
                    service.serviceFeatures.map(item => (
                        <div key={item.id} className={styles.featureWrapper}>
                            <div className={styles.imageWrapper} >
                                <img src={item.img} alt={item.feature} title={item.feature} loading='lazy' width="100%" height="100%" />
                            </div>
                            <h2>{item.feature}</h2>
                        </div>
                    ))
                }
            </div>

            <Link to={`/${field}`}><h3 className={styles.projectsTitle}>{t("services", { ns: "servicesMetaData" })}</h3></Link>

            {
                suggestionService.slice(0, 2).map(item => (
                    <ServicesCard
                        title={item.service}
                        src={item.descriptionImage}
                        body={item.description}
                        path={`/${field}/${item.id}`}
                    />
                ))
            }

            {
                projects.length > 0 &&
                <>
                    <Link to="/ourProjects"> <h3 className={styles.projectsTitle}>{t("projects", { ns: "servicesMetaData" })}</h3> </Link>
                    {
                        projects.slice(0, 2).map(project => (
                            <ProjectCard project={project} key={project.id} />
                        ))
                    }
                </>
            }

        </div>
    )
}

export default ServicesDetails;