import React, { useState } from 'react';
import styles from './RentingCards.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesUp, faAnglesDown } from '@fortawesome/free-solid-svg-icons';
import Animation from '../Animation/Animation';
import { Link } from 'react-router-dom';
import EquipmentCard from '../EquipmentCard/EquipmentCard';

const RentingCards = ({ data, title, description }) => {
    const [getMore, setGetMore] = useState(false);

    return (
        <div className={styles.renting}>
            <Link to={`/rentingServices/${title.toLowerCase()}`} className={`arrow ${styles.equoipmentTitle}`}>{data[0].group}</Link>
            <p>{description}</p>

            <div className={styles.rentingCards}>
                {data.map(item => (
                    <div className={styles.card} key={item.id}>
                        <Animation>
                            <EquipmentCard
                                path={`/rentingServices/${title.replace(' ', '')}/${item.id}`}
                                src={item.images[0]}
                                name={item.name}
                                description={item.description}
                                width={"30%"}
                            />
                        </Animation>
                    </div>
                ))
                }

                {
                    getMore &&
                    data.slice(3).map(item => (
                        <div className={styles.card} key={item.id}>
                            <Animation>
                                <EquipmentCard
                                    path={`/rentingServices/${title.replace(' ', '')}/${item.id}`}
                                    src={item.images[0]}
                                    name={item.name}
                                    description={item.description}
                                    width={"30%"}
                                />
                            </Animation>
                        </div>
                    ))
                }
            </div>

            {
                data.length > 3 ?
                    !getMore ? <FontAwesomeIcon
                        className={`${styles.seeMore} ${styles.down}`}
                        icon={faAnglesDown}
                        onClick={() => setGetMore(true)}
                    />
                        :
                        <FontAwesomeIcon
                            className={`${styles.seeMore} ${styles.up}`}
                            icon={faAnglesUp}
                            onClick={() => setGetMore(false)}
                        />
                    :
                    null
            }
        </div>
    )
}

export default RentingCards;